import React from "react";
import { AccountCouponForm } from "../../../components/PageSegments/subscription/AccountCouponForm";
import { AppLogo } from "../../../components/ui";
import { Content, FlexContainer, LogoContainer, Title } from "./DiscountSSOPage";
import { COUPON_IDS } from "../../../util/variables";

interface DiscountEmailPageProps {
  priceId: string;
  couponId: string;
}

export const DiscountEmailPage = ({ priceId, couponId }: DiscountEmailPageProps) => {
  return (
    <FlexContainer>
      <LogoContainer>
        <AppLogo size="medium" />
      </LogoContainer>
      <Content>
        <Title>Unlock the Future of Learning</Title>
        <AccountCouponForm trial cid={couponId} priceId={priceId} />
      </Content>
    </FlexContainer>
  );
};

DiscountEmailPage.defaultProps = {
  couponId: COUPON_IDS.percent30,
};
