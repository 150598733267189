import React, { useContext } from "react";
import styled from "styled-components";
import { AppLogo } from "../../../components/ui";
import theme from "../../../util/theme";
import { COUPON_IDS, DEFAULT_CURRENCY_CODE, mobileSize } from "../../../util/variables";
import { Link, navigate } from "gatsby";
import { signInWithApple, signInWithGoogle } from "../../../services/firebase-sso";
import {
  discount_flow_applesso_tapped,
  discount_flow_create_account_fail,
  discount_flow_create_account_start,
  discount_flow_create_account_success,
  discount_flow_emailSwitch,
  discount_flow_googlesso_tapped,
} from "../../../services/mixpanel/discount-flow-events";
import { trackAndRedirectUser } from "../../../components/PageSegments/subscription/AccountCouponForm";
import { LogInForm } from "../../ui/LogInForm";
import { LocalPriceContext } from "../../../providers/LocalPriceProvider";

interface DiscountSSOPageProps {
  priceId: string;
  couponId: string;
}

export const DiscountSSOPage = ({ priceId, couponId }: DiscountSSOPageProps) => {
  const { location } = useContext(LocalPriceContext);
  const urlParams =
    typeof window !== "undefined" ? new URLSearchParams(window.location.search) : "";
  const handleGoogleLogin = async () => {
    const SSO_PROVIDER = "google";
    discount_flow_googlesso_tapped();
    discount_flow_create_account_start(SSO_PROVIDER);
    const res = await signInWithGoogle();

    // eslint-disable-next-line no-negated-condition
    if (typeof res !== "boolean") {
      discount_flow_create_account_success(SSO_PROVIDER, res.user.uid, res.user.email, false);
      await trackAndRedirectUser({
        uid: res.user.uid,
        email: res.user.email,
        cid: couponId,
        trial: true,
        priceId,
        currency_code: location?.currency.code ?? DEFAULT_CURRENCY_CODE,
      });
    } else {
      discount_flow_create_account_fail(SSO_PROVIDER, "Failed to login with google");
    }
  };

  const handleAppleLogin = async () => {
    const SSO_PROVIDER = "apple";
    discount_flow_applesso_tapped();
    discount_flow_create_account_start(SSO_PROVIDER);
    const res = await signInWithApple();

    // eslint-disable-next-line no-negated-condition
    if (typeof res !== "boolean") {
      discount_flow_create_account_success(SSO_PROVIDER, res.user.uid, res.user.email, false);
      await trackAndRedirectUser({
        uid: res.user.uid,
        email: res.user.email,
        cid: couponId,
        trial: true,
        priceId,
        currency_code: location?.currency.code ?? DEFAULT_CURRENCY_CODE,
      });
    } else {
      discount_flow_create_account_fail(SSO_PROVIDER, "Failed to login with apple");
    }
  };

  const handleEmailLogin = (autoSelected: boolean) => {
    if (!autoSelected) {
      discount_flow_emailSwitch();
    }

    navigate(`email?${urlParams}`);
  };

  return (
    <FlexContainer>
      <LogoContainer>
        <AppLogo size="medium" />
      </LogoContainer>
      <Content>
        <Title>
          {/* {hasFreeTrial ? "Start Your 7-Day Free Trial" : "Unlock the Future of Learning"} */}
          Unlock the Future of Learning
        </Title>
        <LogInForm
          title="Log in or Create an Account"
          handleEmailLogin={handleEmailLogin}
          handleAppleLogin={handleAppleLogin}
          handleGoogleLogin={handleGoogleLogin}
        />
      </Content>
    </FlexContainer>
  );
};

DiscountSSOPage.defaultProps = {
  couponId: COUPON_IDS.percent30,
};

export const FlexContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: ${theme.WHITE_COLOR};

  @media ${mobileSize} {
    justify-content: flex-start;
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  left: 100px;
  top: 80px;
  display: block;
  @media ${mobileSize} {
    position: static;
    margin: 64px 0;
  }
`;

export const Title = styled.h1`
  margin: auto;
  margin-bottom: 24px;
  font-size: 35px;
  font-weight: 500;
  font-family: ${theme.SECONDARY_FONT};
  text-align: left;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    margin-bottom: 16px;
    font-size: 24px;
    /* font-weight: 700; */
  }
`;

export const Title2 = styled.h2`
  margin-top: 0;
  margin-bottom: 36px;
  font-size: 24px;
  font-weight: 500;
  font-family: ${theme.SECONDARY_FONT};
  text-align: center;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    margin-bottom: 24px;
    font-size: 18px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  max-width: 1000px;
  width: 90vw;
  padding: 80px;
  padding-bottom: 120px;
  border-radius: 16px;
  background-color: #fafafa;
  box-shadow: 0px 1.58px 11px rgba(44, 39, 56, 0.14);

  @media ${mobileSize} {
    padding: 40px 20px;
    min-height: 400px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
`;

const EmailButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 327px;
  max-width: 100%;
  padding: 14px;
  border-radius: 32px;
  border: 2px solid #e3e4e5;
  background-color: #fff;
  color: black;
`;
